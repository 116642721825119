import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import dynamic from 'next/dynamic'
import { useTranslation } from 'react-i18next'

const HomeOffersList = dynamic(() => import('../components/SellOffers/HomeOffersList'))

interface Props {
  title: string
  action: string
  href?: string
  alt?: string
}

const HomeSellOffersContainer: FC<Props> = ({ title, href, action, alt }) => {
  const offers = useSelector((state: Store.RootState) => state.sell.offers)
  const defaultHref = '/offerte-auto'
  const { t } = useTranslation()

  const offersList = offers.filter(offer => offer.code !== 'auto-promo')

  return (
    <HomeOffersList
      title={title}
      action={action}
      href={href || defaultHref}
      alt={alt || action}
      offers={offersList}
    />
  )
}

export default HomeSellOffersContainer
